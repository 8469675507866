@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');

#root{
  height: 100%;
}

$mainPink: #ab60bb;
$mainBlue: #4a43cd;
$ultramarineBlue: #435FF3;
$bgBlue: #f3f3fd;
$white: #fdfdfd;
$black: #444;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F6FB;
}




html {
  scroll-behavior: smooth;
}

.customButton {
  border-radius: 25px;
  padding: 0.4em 1.5em;
  background-image: linear-gradient(to right, $mainBlue, $mainPink);
  color: $white;
  font-family: "Plus Jakarta Sans";
  font-weight: bold;
  border: none !important;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
  font-family: "Plus Jakarta Sans";
}

.customButton:hover:enabled {
  box-shadow: none;
  background-size: 400% 400%;
  animation: hover 5s ease infinite;
  transition: 0.6s;
  //background: #6A4CC4;
  //color:$white;
}

.customButton:disabled,
.customButton[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
  /* background-color: $white !important;
  background-image: none;
  color: #444444; */
}

.customBttonLoading {
  background-size: 400% 400%;
  animation: customBttonLoading 10s linear infinite;
}

@keyframes customBttonLoading {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.tabButton {

  animation: hover 5s ease infinite;
  transition: 0.6s;
  background: #d8d0f3;
  //color:$white;
}
.tabButton:hover:enabled {
  box-shadow: none;
  background-size: 400% 400%;
  animation: hover 5s ease infinite;
  transition: 0.6s;
  //background: #6A4CC4;
  //color:$white;
}
.card {
  position: relative;
  transition: all .5s ease-in;
  box-shadow: 6px 6px 20px rgba(10, 10, 10, 0.3);
  background: $white;
  //max-width: fit-content;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 25px;
  min-width: 300px;
}
.card-item {
  cursor: pointer;
  box-shadow: 6px 6px 20px rgba(10, 10, 10, 0.3);
  background: $white;
  max-width: 75%;
  border-radius: 12px;
  margin-bottom: 25px;
}
.card-item:hover svg{
  animation: swing 1s ease-in-out;
}
.card-item:hover p{
  visibility: visible !important;
  animation: fadeIn 1s ease-in-out;
}

.clickable {
  cursor: pointer;
}
//  .cardContent{

//  }
.card-content-padding {
  padding: 1.6em 1.8em 1.6em 1.8em;
  width: 100%;
}

.mini-card-content-padding {
  padding: 1.06em 1.2em 1.06em 1.2em;
  width: 100%;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.centerText {
  text-align: center;
}

.gradientBackgroundVertical {
  background-image: linear-gradient(to bottom, $mainBlue, $mainPink);
}

.textColor-ligth-blue {
  color: #f3f3fd;
}

.text-color-marine-blue {
  color: #383a59 !important;
}
.underlined {
  text-decoration: underline;
}
.italized {
  font-style: italic;
}
.capitalized {
  text-transform: capitalize;
}
.text-align-right {
  text-align: right;
}
.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
/* Delete this */
.centerText {
  text-align: center;
}
.big-font {
  font-size: 3em;
  //font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: $ultramarineBlue;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #435FF3; //$mainBlue;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.grid-container {
  display: grid;
  grid-template-columns: 25% 50% 20%;
}

.title {
  font-family: "Poppins", sans-serif;
  color: #383a59;
}
.subtitle {
  font-family: "DM Sans", sans-serif;
  color: #767278;
}

/* * {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

.color-red {
  color: #f36f82;
}

.min-w-30-safe {
  min-width: max(30%, 250px);
}
.min-h-30-safe {
  min-height: max(30%, 250px);
}

.max-w-70-safe {
  max-width: max(70%, 320px);
}

.max-w-50-safe {
  max-width: max(50%, 320px);
}

.max-w-85-safe {
  max-width: max(85%, 320px);
}

.max-w-text-safe {
  max-width: max(50%, 300px);
}

@keyframes hover {
  0% {
    background-position: 1% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0%;
  }
}

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

.navbar-items > :not(:last-child) {
  margin-right: 25px;
}

.navbar-selected-page {
  border-bottom: 2px solid $mainBlue;
  font-size: 1.1rem;
  font-weight: bold;
}



.action-icon:hover{
  cursor: pointer;
  animation: rubberBand 1s ease-in-out 0s forwards;
}


table.table-fixed tbody tr td{
  text-overflow: ellipsis;
  overflow: hidden;
}

svg g#woman{
  animation: backInLeft 1s forwards .5s, show 1s forwards .5s
  ;
}
svg g#circle{
  animation: fadeIn 2s forwards 1s, show 1s forwards 1s
  ;
}
svg path#text-1{
  animation: fadeIn 2s forwards 1.5s, show 1s forwards 1.5s, hide 1s forwards 3s;
}
svg path#text-2{
  animation: fadeIn 2s forwards 3.5s, show 1s forwards 3.5s;
}
svg#woman-svg{
  overflow: hidden;
  animation: removeFrom 1s forwards 4.8s
}
@keyframes show {
  0% { height: auto }
  100% { height: 0 }
}
@keyframes show {
  0% { opacity: 0 }
100% { opacity: 1 }
}
@keyframes hide {
  0% { opacity: 1 }
100% { opacity: 0 }
}

#skeleton {
  animation: fadeInUp 2s forwards 5s;
}
svg#phone-svg{
  animation: displayPhone 2s forwards 5s;
}
@keyframes displayPhone {
  0% { height: 0; }
100% { height: auto; }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

#bolt g {
  opacity: 0;
  animation: dropIn 8s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: 4s;
  animation-iteration-count: infinite;
}

@keyframes dropIn {
  20% {
    opacity: 0;
    transform: translateY(-20%);
  }

  30%, 100% {
    opacity: 1;
    transform: translateY(0);
  }

}



@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
 10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
 20% { transform: rotate(-8.0deg) }
 30% { transform: rotate(14.0deg) }
 40% { transform: rotate(-4.0deg) }
 50% { transform: rotate(10.0deg) }
 60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
100% { transform: rotate( 0.0deg) }
}




.tooltipBox {
  position: absolute;
  width: 104px;
  top: 100%;
  left: calc(50% - 52px);
  padding-bottom: 4px;
  /* use padding to preserve hover when moving cursor between the tooltip button and the tooltipItself */
  opacity: 0;
  visibility: hidden;
  /* delay 250ms to give time to fade out */
  transition: opacity 250ms, visibility 1ms 250ms;
}
.tooltipArea.isActive:hover .tooltipBox > button:disabled, .tooltipArea.isActive:focus-within .tooltipBox > button:disabled {
  opacity: 1;
  visibility: visible;
  transition: opacity 250ms;
}
.tooltipArea.isActive:hover .tooltipBox {
  /* delay fadein 250ms to prevent accidental hovers */
  transition: opacity 250ms 250ms;
}

.tooltipItself {
  margin-top: 5px;
  display: block;
  background: #767278;
  /* background-image: linear-gradient(to right, $mainBlue, $mainPink); */
  /* opacity: 0.7; */
  color: #f3ebff;
  padding: 12px;
  font-size: 0.9rem;
  border-radius: 4px;
  text-align: center;
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: initial;
}
.areaEnd {
  position: relative;
  text-align: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}







/**
 * Main wrapper
 */
 .select-search {
  width: 300px;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 100;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
  padding: 0;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 200;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
  z-index: 200;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

input[type=checkbox]:focus{
  outline: 0;
  box-shadow: none;
}

input[type=checkbox]:checked{
  background-image: url('../assets/checkbox-checked.svg');
}

#overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  cursor: pointer;
  opacity: 0.8;
  transition: .5s ease;
  background-color: #393839;
  border-radius: 12px;
}

#text{
  position: absolute;
  width: 90%;
  top: 50%;
  left: 58%;
  color: #fdfdfd;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

.w-c-fit{
  width: fit-content !important;
}

.heading-14 {
  font-family: Gotham, sans-serif;
  font-size: 24px;
  line-height: 130%;
  font-weight: 700;
}

.paragraph-20 {
  padding-right: 0px;
  padding-left: 0px;
  font-family: Gotham, sans-serif;
  color: #383c5d;
  font-size: 16px;
  line-height: 130%;
  font-weight: 400;
}

.heading-4 {
  font-family: Gotham, sans-serif;
  color: #38395b;
  font-size: 48px;
  line-height: 130%;
  text-align: center;
}



.flip-card {
  display: flex;
  justify-content: center;
  width: 300px;
  height: 300px;
}

.flip-card-inner {
  position: relative;
  width: 250px;
  height: 250px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 250px;
  height: 250px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.badge{
  position: relative;
  width: fit-content;
  top: -10px;
  right: -10px;
}

.badge_provider{
  position: relative;
  width: fit-content;
  top: -50px;
  right: 15px;
  float: right;
}
.disable_opt {
  opacity: 0.5;
  pointer-events: none;
}
.active_card{
  background-color: #7aafff08;
  border-radius: 0.5rem;
  border-top-width: 0px;
  border-bottom-width: 1px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  border-color: rgb(229 231 235);
  min-width: 12rem !important; 
}
.inactive_card{
  background-color: #00000005;
  border-radius: 0.5rem;
  border-top-width: 0px;
  border-bottom-width: 1px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  border-color: rgb(229 231 235);
  min-width: 12rem !important;
}


.close-mark-icon:hover{
  fill: red;
  opacity: 0.6;
  transition: ease-in 1s;
}





.welcome-screen #circle1, .welcome-screen #circle2, .welcome-screen #circle3{
    transform-origin: center;
    transform-box: fill-box;
    animation: rotate 50s ease infinite; 
}
.welcome-screen .oon{
  animation-iteration-count: 2;
  animation-delay: 0s, 0.5s;
  animation-duration: 500ms, 1000ms;
  animation-name: pulse, rotate;
}

@keyframes rotate {
  from {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  }
  to {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  }
}


.add-patient-modal .relative.z-10.w-full.py-1.mt-1.overflow-auto.text-base.bg-white.rounded-md.shadow-lg.max-h-60.ring-1.ring-black.ring-opacity-5{
  position: absolute !important;
}


.tsButton:disabled,
.tsButton[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
  /* background-color: $white !important;
  background-image: none;
  color: #444444; */
}

.filled-green-icon path{
  fill: #03582A;
}



#backdrop-parent{
  
  width: 100%;
}

#backdrop-parent:after{
  content:'';
  //background-image: url("../assets/card-assets/bg.png");
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  z-index: 0;
  opacity:0.5;
}


.skeleton {
  animation: skeleton-loading 2s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    
    background-color: #EDF0FF;
  }
  100% {
    background-color: #EDF0FF;
  }
}

.skeleton-text {
  width: 80%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 55%;
}

.skeleton-header {
  width: 20%;
}


.gradient-text {
  background-color: #38395b;
  
  background-image: linear-gradient(to right, $mainBlue, $mainPink);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  background-image: linear-gradient(to right, $mainBlue, $mainPink);
}












.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid $mainBlue;
  width: 100%;
  top: 10px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid $mainBlue;
  width: 100%;
  top: 10px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #EFF1F6;
  margin-bottom: 6px;
  border: 1px solid $mainBlue; 
}


.stepper-item.completed .step-counter {
  background-color: $mainBlue;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid $mainBlue;
  width: 100%;
  top: 10px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}